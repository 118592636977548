const ID_TOKEN_KEY = 'booktour.app.token'

export const getToken = () => {
  if (window.localStorage.getItem(ID_TOKEN_KEY))
  {
    return window.localStorage.getItem(ID_TOKEN_KEY)
  }
  return false;
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }
