import ApiService from '@/core/services/api.service'

const state = {
    dataUsers: {},
    dataNewChallenges: {},
    dataNewTrails: {},
    dataOnHigh: {},
    dataHighlighted: {}
}

const getters = {
    featuredUser (state) {
        return state.dataUsers
    },
    newChallenges (state) {
        return state.dataNewChallenges
    },
    newTrails (state) {
        return state.dataNewTrails
    },
    onHigh (state) {
        return state.dataOnHigh
    },
    Highlighted (state) {
        return state.dataHighlighted
    },
}

const actions = {

    getFeeds(context, params)
    {
        const commitActions = {
            'featured-user': 'setFeaturedUser',
            'new-challenges': 'setNewChallenges',
            'new-trails': 'setNewTrails',
            'on-high': 'setOnHigh',
            'highlighted': 'setHighlighted'
        };

        return ApiService.get('/feeds/home/' + params.filter)
            .then(response =>
            {
                if (response.status === 200)
                {
                    const action = commitActions[params.filter];

                    if (action) {
                        return context.commit(action, response.data);
                    }
                }

                return false;

            }).catch(error => {
                console.log('Erro ao obter os tours do feed: ', error);
            });
    },

    getFeaturedUsers(context, params)
    {
        return ApiService.get('/feeds/home/featured-user')
            .then(response =>
            {
                if (response.status === 200)
                {
                    return context.commit('setFeaturedUser', response.data);
                }

                return false;

            }).catch(error => {
                console.log('Erro ao obter os usuarios do feed: ', error);
            });
    },
}

const mutations =
{
    setFeaturedUser(state, dataResults) {
        state.dataUsers = dataResults.data;
    },
    setNewChallenges(state, dataResults) {
        state.dataNewChallenges = dataResults.data;
    },
    setNewTrails(state, dataResults) {
        state.dataNewTrails = dataResults.data;
    },
    setOnHigh(state, dataResults) {
        state.dataOnHigh = dataResults.data;
    },
    setHighlighted(state, dataResults) {
        state.dataHighlighted = dataResults.data;
    }
}

export default {
  state,
  actions,
  mutations,
  getters
}
