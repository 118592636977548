import { reactive, provide, inject } from 'vue';

export const createStore = () => {
  const state = reactive({
    image: null,
  });

  const setImage = (image) => {
    state.image = image;
  };

  const useStore = () => {
    return {
      state,
      setImage,
    };
  };

  return {
    provideStore: () => {
      provide('state', state);
      provide('setImage', setImage);
    },
    useStore,
  };
};