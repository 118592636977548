import ApiService from '@/core/services/api.service'

const state = {
}

const getters = {

}

// ações
const actions = {

    likeTour(context, idTour)
    {
        return ApiService.post('/tour/' + idTour + '/like').then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            console.error('Erro ao tentar curtir o tour', error);
            return false
        });
    },

    dislikeTour(context, idTour)
    {
        return ApiService.delete('/tour/' + idTour + '/dislike').then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            console.error('Erro ao defavoritar o  tour', error);
            return true
        });
    },

    favoriteTour(context, idTour)
    {
        return ApiService.post('/tour/' + idTour + '/favorite').then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            console.error('Erro ao favoritar o status', error);
        });
    },

    unFavoriteTour(context, idTour)
    {
        return ApiService.delete('/tour/' + idTour + '/remove-favorite').then(response =>
        {
            return response.status === 201;
        })
        .catch(error => {
            //console.error('Erro ao defavoritar o  tour', error);
            return false;
        });
    },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters
}
