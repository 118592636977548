import ApiService from '@/core/services/api.service'
import {showSuccessMessage} from "@/core/utils/alerts/sucess";

const state = {
  topics: {
      data: [],
      meta: {}
  },
}

const getters = {
  dataTopics (state) {
    return state.topics
  }
}

// ações
const actions = {

  selectTypeTour(context, id) {
    context.commit('setTypeTour', id)
  },

    getTopics(context, param) {

        context.commit('clearTopics')

        return ApiService.search('/topics', param).then(response =>
        {
            if (response.status === 200)
            {
                //console.log(response)
                context.commit('setTopics', response.data)
                return response.data;
            }
            else
            {
                console.log(response.status)
            }
        })
        .catch(error => {
            console.error('Erro ao obter topicos', error);
        });
    },

    insertNewTopic(context, param)
    {
        return ApiService.post('/topics', param).then(response =>
        {
            if (response.status === 201)
            {
                showSuccessMessage('Sucesso!', 'Tema criado.');
                return true
            }

            return false
        })
        .catch(error => {
            console.error('Erro ao criar tópico', error);
            return false
        });
    },

    clearTopics(context) {
        context.commit('clear')
    },
}

const mutations = {
    setTopics(state, records) {
        state.topics.data = records.data
        state.topics.meta = records.meta
    },
    clearTopics(state) {
        state.topics.data = []
        state.topics.meta = {}
    },
}

export default {
  state,
  actions,
  mutations,
  getters
}
