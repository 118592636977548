import ApiService from '@/core/services/api.service'

const state = {
}

const getters = {

}

// ações
const actions = {

    fetchBooksAmazon(context, params)
    {
        const queryString = new URLSearchParams(params).toString();

        return ApiService.get("/books/fetch-books?"+queryString).then(response =>
        {
            //console.log(response.data.SearchResult)
            return response.data.SearchResult
        })
        .catch(error => {
            console.log('Erro ao procurar livros na amazon', error);
            return false
        });
    },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters
}
