import axios from 'axios'
import JwtService from '@/core/services/jwt.service'
import Swal from 'sweetalert2/dist/sweetalert2';
import store from '../../stores/index'; // Importe a store do Vuex
import {showWarningMessage} from '../alerts/warning'

export default function setup () {

  // Adicionar um contador para rastrear requisições pendentes
  let requestCount = 0;

  function updateLoadingStatus() {
    store.commit('setLoading',requestCount > 0);
  }

  axios.interceptors.request.use(
    function (config) {

      requestCount++;
      updateLoadingStatus();

      const token = JwtService.getToken();
      config.headers['Authorization'] = `Bearer ${token}`;

      return config
    },
    function (err) {
      requestCount--;
      updateLoadingStatus();
      return Promise.reject(err)
    }
  )

  const showAlert = (title, message) => {
    Swal.fire({
      position: 'center',
      showConfirmButton: true,
      timerProgressBar: true,
      icon: 'error',
      title: title,
      html: message,
    });
  };

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
      requestCount--;
      updateLoadingStatus();
      return response
    }, function (error) {

    requestCount--;
    updateLoadingStatus();

   const { status, data } = error.response;

    switch (status) {
      case 422:
        const allErrors = Object.values(data.errors)
            .flat()
            .join(' ');

        showWarningMessage('Erro!', allErrors);
        return Promise.reject(error);

      case 400:
      case 403:
      case 404:
      case 500:
        showWarningMessage('Erro!', data.message);
        return Promise.reject(error);
        break;

      default:
        showWarningMessage('Erro!', 'Entre em contato.');
        return Promise.reject(error);
        break;
    }

  })
}
