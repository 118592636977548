import Swal from 'sweetalert2';

export function showWarningMessage(title, text) {
    Swal.fire({
        title: title || 'Atenção!',
        text: text || 'Erro ao realizar operação.',
        icon: 'warning',
        heightAuto: false
    });
}
