import ApiService from '@/core/services/api.service'
import { showSuccessMessage } from '@/core/utils/alerts/sucess';
import router from '@/router';
import {ATUALIZAR_PASSWORD, OBTER_DADOS_USUARIO_LOGADO} from "@/core/stores/modules/users.module";
import {LOGOUT, PURGE_AUTH} from "@/core/stores/modules/auth.module";

const state = {
    errors: null,
    tour: {},
    tourItems: {},
    dataSearchTours: {},
    userTours: {},
    userToursFavorites: {},
    userToursLikes: {}
}

const getters = {
    tour (state) {
        return state.tour
    },
    tourItems (state) {
        return state.tourItems
    },
    isAdminTour (state)
    {
        if (state.tour.roles)
        {
            return state.tour.roles.edit;
        }

        return false;
    },
    dataSearchTours (state) {
        return state.dataSearchTours
    },
    dataUserTours (state) {
        return state.userTours
    },
    dataUserToursFavorites (state) {
        return state.userToursFavorites
    },
    dataUserToursLikes (state) {
        return state.userToursLikes
    },
}

// ações
const actions = {

    searchTours(context, params)
    {
        context.commit('clearSearchTours')

        return ApiService.search('/tour/search/', params).then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setDataSearchTours', response.data)
                return response.data;
            }

            return false;

        }).catch(error => {
            console.error('Erro ao obter topicos', error);
        });
    },

    getTour(context, id)
    {
        return ApiService.get('/tour/' + id).then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setTour', response.data)
                return true
            }

            return false
        })
        .catch(error => {
            console.error('Erro ao obter topicos', error);
        });
    },

    getToursByUser(context, payload)
    {
        const commitActions = {
            'user-tours': 'setCurrentUserTours',
            'user-favorites': 'setCurrentUserToursFavorites',
            'user-likes': 'setCurrentUserToursLikes',
        };

        const params = new URLSearchParams();
        params.append('filter', payload.filter);
        params.append('idUserSearch', payload.idUser);

        return ApiService.get('/tour/search?' + params.toString())
            .then(response =>
            {
                if (response.status === 200)
                {
                    const action = commitActions[payload.filter];

                    if (action) {
                        return context.commit(action, response.data);
                    }
                }

                return false;

            }).catch(error => {
                console.log('Erro ao obter os tours do usuário: ', error);
            });
    },

    getItemsTour(context, idTour)
    {
        return ApiService.get('/item/tour/' + idTour).then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setItemsTour', response.data)
                return true;
            }

            return false;

        }).catch(error => {
            console.error('Erro ao obter topicos', error);
        });
    },

    publishTour(context, idTour)
    {
        return context.dispatch('alterStatus', {idTour: idTour, newStatus: 'publish'});
    },

    finishTour(context, idTour)
    {
        return context.dispatch('alterStatus', {idTour: idTour, newStatus: 'completed'});
    },

    alterStatus(context, params)
    {
        return ApiService.post('/tour/' + params.idTour + '/' + params.newStatus).then(response =>
        {
            if (response.status === 200)
            {
                return context.dispatch('getTour', params.idTour).then(() => {
                    return true;
                }).catch(() => {
                    return false;
                });

            }
            else
            {
                return false;
            }
        })
        .catch(error => {
            console.error('Erro ao alterar status', error);
        });
    },

    clearDataTour(context) {
        context.commit('clearTour')
    },
}

const mutations = {
    setDataSearchTours(state, records) {
        state.dataSearchTours = records
    },
    setTour(state, record) {
        state.tour = record.data
    },
    setCurrentUserTours(state, record) {
        console.log(record)
        state.userTours = record.data
    },
    setCurrentUserToursFavorites(state, record) {
        state.userToursFavorites = record.data
    },
    setCurrentUserToursLikes(state, record) {
        state.userToursLikes = record.data
    },
    setItemsTour(state, records) {
        state.tourItems = records.data
    },
    clearTour(state) {
        state.tour = {}
        state.tourItems = {}
    },
    clearSearchTours(state) {
        state.dataSearchTours = {}
    },
}

export default {
  state,
  actions,
  mutations,
  getters
}
