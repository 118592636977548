import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/stores";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import ApiService from "@/core/services/api.service";

import interceptorsSetup from "@/core/utils/helpers/interceptors";

import Vue from "vue";

const app = createApp(App);

// API service init
ApiService.init(app);
//ApiService.setHeader();
interceptorsSetup();

// Use Vuex store and Vue Router
app.use(store);
app.use(VueSweetalert2);

//  Load App
const isAppLoaded = ref(false);
app.provide("isAppLoaded", isAppLoaded);

/*router.isReady().then(() => {
  setTimeout(() => {
    isAppLoaded.value = true;
  }, 3000);
});*/

router.isReady().then(() => {
    isAppLoaded.value = true;
});

// Mount the app
app.use(router).mount("#app");

// Global Components
app.component("alert", require("./components/bootstrap/alert/alert").default);
app.component("toast", require("./components/bootstrap/toasts/toast").default);
app.component(
  "toast-header",
  require("./components/bootstrap/toasts/toast-header").default
);
app.component(
  "toast-body",
  require("./components/bootstrap/toasts/toast-body").default
);
