import ApiService from '@/core/services/api.service'
import { showSuccessMessage } from '@/core/utils/alerts/sucess';


const state = {
  errors: null,
  dataTour: {}
}

const getters = {
  newTour (state) {
    return state.dataTour
  }
}

// ações
const actions = {

  selectTypeTour(context, id) {
    context.commit('setTypeTour', id)
  },

    insertNewTour(context, param)
    {
        return ApiService.postForm('/tour', param).then(response =>
        {
            if (response.status === 201)
            {
                context.dispatch('clearDataTour');
                return response.data.id;
            }

            return false

        })
        .catch(error => {
            return false
        });
    },
    clearDataTour(context) {
        context.commit('clear')
    },
}

const mutations = {
    setTypeTour(state, data) {
        state.dataTour.type = data
    },
    clear(state) {
        state.dataTour = {}
    },
}

export default {
  state,
  actions,
  mutations,
  getters
}
