<template>
  <main class="splash-screen" v-if="!isAppLoaded">
    <img src="../../assets/images/icn-logo-branco.png" alt="">
  </main>

  <router-view v-else />
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'SplashScreen',
  setup() {
    const isAppLoaded = inject('isAppLoaded');
    return { isAppLoaded };
  }
};
</script>