import Swal from 'sweetalert2';

export function showSuccessMessage(title, text) {
    Swal.fire({
        title: title || 'Sucesso!',
        text: text || 'A operação foi realizada com sucesso.',
        icon: 'success',
        heightAuto: false
    });
}
