<template>
  <loading-spinner></loading-spinner>
  <!--<SplashScreen />-->
  <router-view />
</template>

<script>

import SplashScreen from './views/_backup_raiz/SplashScreen.vue'
import LoadingSpinner from './LoadingSpinner.vue';

import { ref, provide } from 'vue';
import { createStore } from '@/views/_backup/share/state/store.js';
const store = createStore();

export default {

  components: {
    //SplashScreen,
    LoadingSpinner
  },

  // setup() {
  //   store.provideStore();
  // },

  setup() {
    store.provideStore();

    const captureContent = async (content) => {
      const sourceComponent = ref(null);
      if (sourceComponent.value) {
        sourceComponent.value.captureContent(content);
      }
    };

    return {
      captureContent,
    };
  },
  methods: {
    handleCapture(content) {
      this.captureContent(content);
    },
  },
}
</script>

<style src="./assets/scss/index.scss" lang="scss" />
