import ApiService from '@/core/services/api.service'
import {showSuccessMessage} from "@/core/utils/alerts/sucess";

const state = {
    comments: {},
    meta: {},
}

const getters = {
    commentsTour (state) {
        return state.comments
    },
    metaComments (state) {
        return state.meta
    },
}

const actions = {

    getCommentsTour(context, idTour)
    {
        return ApiService.get('/comments/tour/' + idTour).then(response =>
        {
            if (response.status === 200)
            {
                context.commit('setComments', response.data)
                return true;
            }
            else
            {
                console.log(response.status)
            }
        })
        .catch(error => {
            console.error('Erro ao obter os comentários', error);
        });
    },
    insertNewCommentTour(context, params)
    {
        return ApiService.postForm('/comments', params).then(response =>
        {
            if (response.status === 201)
            {
                showSuccessMessage('Sucesso!', 'Comentário criado!');
                return true;
            }

            return false;

        })
        .catch(error => {
            console.error('Erro ao adicionar comentário no tour', error);
        });
    },
    deleteComment(context, params)
    {
        return ApiService.delete('/comments/' + params.idComment).then(response =>
        {
            if (response.status === 204)
            {
                return context.dispatch('getCommentsTour', params.idTour).then(() => {
                    return true;
                }).catch(() => {
                    return false;
                });
            }
            else
            {
                return false;
            }
        })
        .catch(error => {
            console.error('Erro ao alterar status', error);
        });



        return ApiService.delete('/comments/', idComment).then(response =>
        {
            if (response.status === 201)
            {
                return true;
            }

            return false;
        })
        .catch(error => {
            console.error('Erro ao deletar comentário no tour', error);
        });
    },
}

const mutations = {
    setComments(state, records) {
        state.comments = records.data
        state.meta = records.meta
    },
}

export default {
  state,
  actions,
  mutations,
  getters
}
