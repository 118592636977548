import { createRouter, createWebHistory } from 'vue-router'
import {OBTER_DADOS_USUARIO_LOGADO} from "@/core/stores/modules/users.module"
import store from '@/core/stores/index';


const routes = [
  {
    path: '',
    name: 'index',
    component: () => import(/* webpackChunkName: "register" */ '../views/IndexView.vue'),
    meta: {
      title: '',
      returnRoute: ''
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Authentication/RegisterView.vue'),
    meta: {
      title: 'Cadastro'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Authentication/LoginView.vue'),
    meta: {
      title: '',
      returnRoute: ''
    }
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: () => import(/* webpackChunkName: "login" */ '../views/Authentication/RecoverPasswordView.vue'),
    meta: {
      title: '',
      returnRoute: ''
    }
  },
  {
    path: '/recover-password-confirmation',
    name: 'recover-password-confirmation',
    component: () => import(/* webpackChunkName: "login" */ '../views/Authentication/RecoverPasswordConfirmationView.vue'),
    meta: {
      title: '',
      returnRoute: ''
    }
  },
  {
    path: '/feeds',
    name: 'feeds',
    component: () => import(/* webpackChunkName: "explore" */ '../views/Feeds/ExploreFeeds.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/search/:query?',
    name: 'tour.search',
    component: () => import(/* webpackChunkName: "explore" */ '../views/Feeds/ExploreFeedsSearch.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/select',
    name: 'select',
    component: () => import(/* webpackChunkName: "select" */ '../views/_backup_raiz/ObjectiveView.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/tour/create/select-type',
    name: 'tour.create.selectType',
    component: () => import(/* webpackChunkName: "mapOne" */ '../views/Tours/CreateTourSelectType.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/tour/create/insert-data',
    name: 'tour.create.insert-data',
    component: () => import(/* webpackChunkName: "addThemeOne" */ '../views/Tours/CreateTourInsertData.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/tour/edit/select-topic/:id',
    name: 'tour.edit.select-topic',
    component: () => import(/* webpackChunkName: "mapOne" */ '../views/Tours/CreateTourSelectTopic.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/tour/edit/select-book/:tour/:item',
    name: 'tour.edit.select-book',
    component: () => import(/* webpackChunkName: "mapOne" */ '../views/Tours/CreateTourSelectBook.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/tour/edit/resume-topic/:id',
    name: 'tour.edit.resume-topic',
    component: () => import(/* webpackChunkName: "mapOne" */ '../views/TourItem/ResumeTopicTour.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/admin/tours/:filter?',
    name: 'admin.user.tours',
    component: () => import(/* webpackChunkName: "myTours" */ '../views/Admin/Tour/HomeAdminTours.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: ''
    },
  },
  {
  path: '/admin',
  name: 'admin.home',
  component: () => import(/* webpackChunkName: "configPage" */ '../views/Admin/HomeAdmin.vue'),
  meta: {
    middleware: 'auth',
    title: '',
    returnRoute: ''
  },
  },
  {
    path: '/users/admin',
    name: 'users.admin',
    component: () => import(/* webpackChunkName: "configPage" */ '../views/Users/HomeConfigUsers.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: 'feeds'
    },
  },
  {
    path: '/editProfile',
    name: 'editProfile',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/Users/EditProfile.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/users/config/editEmail',
    name: 'admin.user.config.email',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/Users/EditEmail.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/users/config/editEmailVerify',
    name: 'admin.user.config.email.verify',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/Users/EditEmailVerify.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/users/config/editUsername',
    name: 'editUsername',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/Users/EditUsername.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/users/config/editPassword',
    name: 'editPassword',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/Users/EditPassword.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/users/config/editAvatar',
    name: 'editAvatar',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/Users/EditAvatar.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: 'users.admin'
    },
  },
  {
    path: '/tour/:id',
    name: 'tour.detail',
    component: () => import(/* webpackChunkName: "mapOne" */ '../views/Tours/DetailTour.vue'),
    meta: {
      middleware: "auth",
      title: '',
      returnRoute: 'feeds'
    },
  },
  {
    path: '/tour/comments/:id',
    name: 'tour.comments.home',
    component: () => import(/* webpackChunkName: "rate" */ '../views/Comments/CommentsTourHome.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      //returnRoute: 'tour.detail'
      returnRoute: 'tour.detail'
    },
  },
  {
    path: '/tour/comments/post/:id',
    name: 'tour.comments.post',
    component: () => import(/* webpackChunkName: "avaliations" */ '../views/Comments/CommentsForm.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
  {
    path: '/user/:id',
    name: 'user.details.home',
    component: () => import(/* webpackChunkName: "avaliations" */ '../views/Users/UserDetails.vue'),
    meta: {
      middleware: 'auth',
      title: '',
      returnRoute: ''
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((toRoute, from, next) => {

  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Booktour - Metas Literárias';

  // Verifica se a rota requer autenticação
    if (toRoute.meta.middleware === 'auth')
    {
      store.dispatch(OBTER_DADOS_USUARIO_LOGADO).then(isAuthenticated => {
        if (isAuthenticated) {
          next();
        } else {
          next({name: 'login'});
        }
      }).catch(() => {
        next({ name: 'login' });
      });
    }
    else {
      //console.log('rota publica')
      next();
    }
});
export default router
