<!-- LoadingSpinner.vue -->
<template>
  <div v-if="loading" class="loading style-2">
    <div class="loading-wheel">
      <img class="bag-logo" src="@/assets/images/icn-logo-branco.png" alt="">
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['isLoading']),
    loading() {
      return this.isLoading;
    }
  }
}
</script>

<style scoped>
.loading {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(55, 55, 55, 0.5);
}
.bag-logo {
  animation:s2 1s infinite linear;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: pulse-load 1s infinite linear;
}

@keyframes pulse-load {
  0% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
